@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height from variables;

.ContactListCategory {
    margin-bottom: 2rem;
}
.CategoryHeader {
    margin-top: 0;
    border-bottom: 1px solid var(--gray-mid);
    padding-bottom: .5rem;
    font-size: 1.4rem;
    overflow: hidden;
}
.List {
    padding: 0;
    margin: 0 0 1rem;
    list-style: none;
}
.Buttons {
    display: flex;
    justify-content: flex-start;
}
.PriorityShiftButtons {
    margin: 0 1rem 0 0;
    flex: 0 0 auto;
}
.EditBtn, .DeleteBtn {
    flex: 0 1 auto;
}
.DeleteBtn {
    margin-left: .5rem;
}
@media only screen and (min-width: _menuwidth)  {
    .Buttons {
        display: inline-flex;
        float: right;
    }
}