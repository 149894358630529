
.Quill {
 padding: 10px 0;
}
.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}
:global(.quill-img-align-left) {
    float: left;
    margin: 0px 1em 1em 0px !important;
}
:global(.quill-img-align-right) {
    float: right;
    margin: 0px 0px 1em 1em !important;
}
:global(.quill-img-align-center) {
    margin: auto !important;
    display: block;
}