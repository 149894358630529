.ql-align-right {
    text-align: right;
}
.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
    margin: 0 0 1em !important;
}