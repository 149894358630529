@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height from variables;

.Toolbar {
    width: 100vw;
    height: _toolbar_height;
    background: var(--burgundy-dark);
    display: flex;
    justify-content: space-evenly;
    z-index: 500;
    box-sizing: content-box; /* Needed for safe-area padding */
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
}

@media only screen and (min-width: _menuwidth) {
    .Toolbar {
        position: fixed;
        top: 0;
        padding-bottom: 0;
    }
}
@media only screen and (min-width: _menuwidth) and (orientation: portrait) {
    .Toolbar {
        padding-top: env(safe-area-inset-top);
    }
}
