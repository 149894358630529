@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height from variables;

.Layout {
    background: var(--off-white);
    min-height: 100vh;
    box-sizing: content-box;
}
@media only screen and (min-width: _menuwidth) {
    .Layout {
        
    }
}

:global(body) {
    background: var(--burgundy-dark)
}