:root {
    --main-text-color: #333;
    --pink: #D8A5D2;
    --lavender-very-light: #f7ecf9;
    --lavender-light: #e7d4ea;
    --lavender: #c59fcc;
    --burgundy-light: #cc6699; /* start of gradient */
    --burgundy-mid: #b5538e; /* mid of gradient */
    --burgundy: #993399; /* end of gradient */
    --burgundy-dark: #5e155e; /* toolbar */
    /* main button colors */
    --navy-blue-light: #638cd4;
    --navy-blue-mid: #4873bd; 
    --navy-blue: #2f579a; 
    --navy-blue-dark: rgb(15, 43, 97);
    --navy-blue-deep: #343d94; 
    /* End button colors */
    --very-light-blue: #e5f1f5;
    --light-blue: #adbbe4;
    --very-light-purple: #fdfdff;
    --light-orange: #ffdf9f;
    --very-light-orange: #F7F2E8;
    --off-white: #fcfbf8;
    --gray-light: #c8cad1;
    --gray-mid: #707172;
    --gray: #606263; 
    --gray-dark: #404041;
    --gray-deep: #323235;

    /* Layout */
    --sidebar_width: 16rem;
    --toolbar_height: 2.4rem;
    --mobile_header_height: 1.7rem;
    --mobile_header_padding: .5rem;
    --mobile_header_height_with_padding: calc(var(--mobile_header_height) + 2*(var(--mobile_header_padding)));
    --content_pane_header: 4.1rem;
    --content_pane_side_margin: 1rem;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    color: var(--main-text-color);
    background: var(--very-light-purple);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    font-size: 1.7rem;
    color: var(--burgundy-dark);
}
h2 {
    font-size: 1.4rem;
    color: var(--burgundy-dark);
}
h3 {
    font-size: 1.3rem;
}
h4 {
    font-size: 1.2rem;
}
h5 {
    font-size: 1.1rem;
}
h6 {
    font-size: 1rem;
}