.Modal {
    position: fixed;
    z-index: 500;
    width: 90vw;
    max-height: 90vh;
    max-width: 900px;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    overflow: scroll;
}
