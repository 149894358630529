.UserTokens {
    margin: 2rem 0;
}

.UserTokenList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.UserTokensTitle {
    margin-top: 0;
}