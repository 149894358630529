
.RequestAccessButton, .ResetPasswordButton {
    border: 0;
    background: 0;
    text-decoration: none;
    color: #fff;
    margin: 1rem 0 0;
    cursor: pointer;
}
.RequestAccessButton a, .ResetPasswordButton a {
    color: #fff;
    text-decoration: none;
}
.RequestAccessButton:focus, .ResetPasswordButton:focus {
    outline: none;
}
@media (hover: hover) { 
    .RequestAccessButton:hover, .ResetPasswordButton:hover {
        color: #fff;
    }
}