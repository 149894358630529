.ListItem {
    border:1px solid #ddd;
    padding: .3rem .5rem;
    margin: .3rem 0;
}
.AdminAddPageForm {
    display: inline-block;
}
.AddPageListItem {
    padding: .3rem 0 .3rem;
}

.PriorityWrapper {
    display: inline-block;
    margin-right: .5rem;
}
.TitleWrapper {
    display: inline-block;
    margin-right: .5rem;
}
.SubmitWrapper {
    display: inline;
}
.Submit {}