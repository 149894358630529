@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _maxwidth from variables;

.UserTokenItem {
    display: flex;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px dotted #ccc;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
}

.UserEmail {
    background: white;
    padding: .5rem;
}

.Buttons {
    padding-top: .5rem;
}

.Buttons button {
    margin-right: .5rem;
    font-size: .9rem;
}

.Email {
    font-weight: 700;
}

.Token, .DateAdded {
    display: block;
    font-size: .8rem;
    margin: .5rem 0 0;
}

@media only screen and (min-width: _menuwidth) {
    .UserTokenItem {
        flex-direction: row;
    }
    .Email {
        flex: 1 1 25%;
    }
    .Role {
        flex: 1 1 15%;
        padding-left: .5rem;
        display: inline;
    }
    .Buttons {
        flex: 1 1 auto;
        text-align: right;
        padding-top: 0;
    }
}