.ListItem {
    padding: .5rem .5rem .2rem .5rem;
    margin: .3rem 0;
}
.ListItem.Level0 {
    padding: .5rem .5rem .2rem .5rem;
    margin: .3rem 0;
}

.PageControls {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.PriorityShiftButtons {
    flex: 0 0 auto;
    padding-right: 1rem;
}
.Title {
    font-weight: 700;
    flex: 1 1 auto;
    text-align: left;
}
.Title.Level0 {
    font-size: 1.2rem;
}

.EditButtons {
    flex: 0 0 auto;
    position: relative;
    bottom: 1px;
}

.PriorityUpButton, .PriorityDownButton {
    background: none;
    border: 0;
    padding: 2px 4px;
    line-height: 1;
    cursor: pointer;
    border: 1px solid #000;
    position: relative;
}
.PriorityUpButton i, .PriorityDownButton i {
    height: 12px;
    position: relative;
    top: -2px;
}
@media (hover: hover) { 
    .PriorityUpButton:hover, .PriorityDownButton:hover {
        background: #eeddee;
    }
}

.PriorityUpButton:focus, .PriorityDownButton:focus {
    outline: 0;
}
.PriorityNum {
    margin: 0 .5rem;
    position: relative;
    top: 2px;
    color: #777;
}

