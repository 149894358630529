
.List {
    list-style: none;
    margin: .5rem 0;
    padding: .3rem .5rem;
    border: 1px solid #ddd;
    background: #f3f3f3;
}
.List.Level0 {
    margin: .5rem 0;
    padding: 0;
    border: 0;
    background: none;
}