.UserList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.UserListTitle {
    margin-top: 0;
}

