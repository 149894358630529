.ImgZoom {
    position: fixed;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: rgb(68, 68, 68);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    overflow: scroll;
    padding-top: calc(env(safe-area-inset-top));
}

.ImgZoom img {
    max-width: none;
}

.CloseBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 3px;
    padding: .2em .3em .35em;
    background: rgba(0,0,0,0.8);
    color: #fff;
    border: 1px solid rgba(255,255,255,0.9);
    z-index: 1000;
    top: calc(env(safe-area-inset-top) + 10px);
}