
.ToolbarButton {
    background: transparent;
    border: 0;
}
.ToolbarButton, .ToolbarButton a {
    color: var(--pink);
}
@media (hover: hover) { 
    .ToolbarButton:hover, .ToolbarButton:hover a {
        color: var(--light-orange);
    }
}

.ToolbarButton:focus {
    outline: 0;
}