@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _mobile_header_height_with_padding, _sidebar_width from variables;

.SideMenu {
    --padding-cap: 0.6rem;

    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: _sidebar_width;
    height: 100vh;
    padding: var(--padding-cap) 0;
    z-index: 450;
    background: var(--very-light-orange);
    display: none;
    padding-top: calc(env(safe-area-inset-top) + _mobile_header_height_with_padding + var(--padding-cap));
    padding-bottom: calc(env(safe-area-inset-bottom) + _toolbar_height + var(--padding-cap));
    overflow-y: auto; 
    border-left: 2px solid var(--burgundy-dark)
}

@media (hover: none) {
    .SideMenu {
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }
}
@media only screen and (orientation: landscape) {
    .SideMenu {
        padding-top: calc(env(safe-area-inset-top) + _mobile_header_height_with_padding + var(--padding-cap));
        padding-bottom: calc(env(safe-area-inset-bottom) + _toolbar_height + var(--padding-cap));
    }
}

@media only screen and (min-width: _menuwidth) {
    .SideMenu {
        position: fixed;
        display: block;
        height: 100vh;
        width: _sidebar_width;
        top: 0;
        bottom: 0;
        right: 0;
        float: right;
        padding-top: calc(_toolbar_height + var(--padding-cap));
        padding-bottom: 1rem;
    }
}

@media only screen and (min-width: _menuwidth) and (orientation: portrait) {
    .SideMenu {
        padding-top: calc(env(safe-area-inset-top) + _mobile_header_height_with_padding + var(--padding-cap));
        padding-bottom: calc(env(safe-area-inset-bottom) + _toolbar_height + var(--padding-cap));
    }
}
