
.Adminaddcontact {

}
.ListItem {
    list-style: none;
    margin: 0;
    padding: 0;
}
.Category {
    max-width: 20rem;
}
.FirstName, .LastName, .Honorific, .Phone {
    max-width: 12rem;
}
.Title, .Email {
    max-width: 20rem;
}
.PhoneType {
    width: 8rem;
}
.PhoneType:invalid {
    color: #999;
}
.NameWrapper {
    display: inline-block;
    padding-right: .5rem;
}
.PhoneWrapper {
    float: left;
    clear: left;
    margin-bottom: .5rem;
}
.PhoneTypeWrapper {
    float: left;
    margin: .3rem 0 0 .5rem;
}
.SubmitWrapper {
    clear: left;
    padding: 1rem 0;
}
.CancelButton {

}