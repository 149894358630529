.AlertTemplate {
    background-color: var(--burgundy-dark);
    color: var(--off-white);
    padding: 10px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
    width: 300px;
    box-sizing: border-box;
    font-size: .8rem;
}

.AlertButton {
    margin-left: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #FFFFFF;
}