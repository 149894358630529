@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _navy_blue from variables;

.ManualNavItem {
    margin: 0;
    padding: 0 .2rem 0 0;
}
.ManualNavItem.Level0 {
    border-bottom: 1px solid var(--light-blue);
    margin-bottom: .7rem;
    padding-bottom: .2rem;
}
.NavLink {
    padding: 0 0 0 .95rem;
    margin: .6rem 0;
    text-decoration: none;
    color: var(--navy-blue);
    display: block;
    position: relative;
    transition: color .5s;
    font-size: 0.95rem;
    padding-right: calc(env(safe-area-inset-right) - 10px);
}

.NavLink.Level0 {
    font-weight: 700;
}

.NavLink::before {
    content: '\25B6';
    font-size: .9rem;
    margin-right: .4rem;
    position: absolute;
    top: .05rem;
    left: -.1rem;
    color: transparent;
    transition: color .5s;
}

@media (hover: hover) { 
    .NavLink:hover, .NavLink:hover::before {
        color: var(--burgundy);
        opacity: 1;
    }
}

.Active, .Active::before {
    color: var(--burgundy-dark) !important;
    opacity: 1;
}
