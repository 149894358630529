@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _maxwidth from variables;

.UserListItem {
    display: flex;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px dotted #ccc;
    justify-content: space-between;
    flex-direction: column;
}

.Buttons {
    padding-top: .5rem;
}

.Buttons button {
    margin-right: .5rem;
    font-size: .9rem;
}

.Role {
    font-weight: 700;
    display: block;
}

.InputWrapper {
    margin: 0;
}

.RoleSelect {
    padding: 1px 6px 4px 4px;
    border: 0;
    background: transparent;
    width: auto;
    border: 1px solid transparent;
}

.RoleSelect:hover {
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.3);
}
.RoleSelect:focus {
    background: inherit;
}

@media only screen and (min-width: _menuwidth) {
    .UserListItem {
        flex-direction: row;
    }
    .Email {
        flex: 1 1 25%;
        align-self: center;
    }
    .RoleSwitch {
        flex: 1 1 15%;
        padding-left: .5rem;
        display: inline;
    }
    .Buttons {
        flex: 1 1 auto;
        text-align: right;
        padding-top: 0;
    }
}