@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height from variables;

.ContactList {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}
.List {
    padding: 0;
    margin: 0 0 1rem;
    list-style: none;
}
.AdminControls {
    padding: 0.6rem 0.6rem 0.6rem;
    border: 1px solid var(--gray-light);
    margin: 0 0 1rem;
    background: var(--very-light-purple);
}
.AdminButtons {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.AdminButtons li {
    margin-right: 1rem;
    margin-bottom: 0.6rem;
}
.AdminButtons li:last-child {
    margin: 0;
}

@media only screen and (min-width: _menuwidth)  {

    .AdminButtons {
        flex-direction: row;
    }
    .AdminButtons li {
        margin-bottom: 0;
        margin-right: 0.6rem;
    }
}