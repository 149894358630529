.InputWrapper {
    width: 100%;
    margin: 1rem 0;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: rgb(255, 251, 228);
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}
.HelperText {
    font-size: .9rem;
    color: #666;
    font-style: italic;
}