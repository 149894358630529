.MobileAppInstall {

}
.HowTo, .HowTo ol {
    margin: 1rem 0 0 0;
    padding: 0 0 0 1.5rem;
}
.HowTo li {
    margin: 0 0 .5rem;
    max-width: 500px;
}

.ClickHere {
    padding: 0.3rem 0.5rem;
    display: inline-block;
    background-color: var(--pink);
    border: 2px solid var(--burgundy-dark);
    border-radius: 5px;
    color: var(--burgundy-dark);
    font-weight: 700;
    margin: 0 .5rem;
    text-decoration: none;
    transition: all;
}

.ClickHere:hover {
    background-color: var(--light-orange);
}

.HowTo img {
    max-height: 500px;
}