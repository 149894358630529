.ContentPaneBlock {

}

.Content img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
}
.Title {
    margin-top: 0.8rem;
    font-size: 1.3rem;
}