@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _mobile_header_height, _mobile_header_padding from variables;

.MobileHeader {
    background: var(--burgundy-dark);
    position: fixed;
    height: _mobile_header_height;
    width: 100%;
    color: var(--off-white);
    font-weight: 700;
    font-size: 1.3rem;
    padding-right: env(safe-area-inset-right);
    padding-left: calc(env(safe-area-inset-left) + 1rem);
    padding-top: calc(env(safe-area-inset-top) + _mobile_header_padding);
    padding-bottom: _mobile_header_padding;
    box-sizing: content-box;
    z-index: 500;
    top: 0;
}
@media only screen and (min-width: _menuwidth) {
    .MobileHeader {
        display: none;
    }
}