@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _mobile_header_height, _sidebar_width from variables;

.Menubtn {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 10px 5px 9px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    margin-right: 0px;
    position: fixed;
    top: 0px;
    margin-top: env(safe-area-inset-top);
    right: calc(env(safe-area-inset-right) + 1rem);
}

.Menubtnbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 15px;
}

.Menubtninner, .Menubtninner::after, .Menubtninner::before {
    position: absolute;
    width: 25px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 2px;
    background-color: var(--off-white);
}
.Menubtninner {
    top: 50%;
    display: block;
    margin-top: -2px;
}
.Menubtninner::after, .Menubtninner::before {
    display: block;
    content: "";
}
.Menubtninner::before {
    top: -10px;
}
.Menubtninner::after {
    bottom: -10px;
}

.Squeeze .Menubtninner:before {
    transition: top 75ms ease .12s,opacity 75ms ease;
}
.Squeeze .Menubtninner {
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: 75ms;
}
.Squeeze .Menubtninner::after {
    transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
}

.is-active .Menubtninner, .Active .Menubtninner:after, .Active .Menubtninner:before {
    background-color: #fff;
}

.Squeeze.Active .Menubtninner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(45deg);
}

.Squeeze.Active .Menubtninner:before {
    top: 0;
    transition: top 75ms ease,opacity 75ms ease .12s;
    opacity: 0;
}

.Squeeze.Active .Menubtninner:after {
    bottom: 0;
    transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(-90deg);
}

@media only screen and (min-width: _menuwidth) { 
    .Menubtn {
        display: none;
    }
}