@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _maxwidth from variables;

.AdminUserManager {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.AddUserWrapper {
    border: 1px solid var(--burgundy-dark);
    padding: .5rem 2rem 1.5rem;
    margin: 2rem 0;
    border-radius: 4px;
    background: var(--very-light-blue)
}

.LoaderWrapper {
    margin: 3rem auto;
    text-align: center;
}

@media only screen and (min-width: _widescreen) {
    .AdminUserManager {
        max-width: _maxwidth;
    }
    .AdminUserManager {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .AddUserWrapper {
        flex: 0 0 30%;
    }
    .UserListWrapper {
        flex: 0 0 60%;
    }
    .AddUserWrapper {
        flex: 0 0 30%;
    }
}