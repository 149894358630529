
.ListItem {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dotted #ccc;
}
.Name {
    font-weight: 700;
    font-size: 1.1rem;
    color: rgb(17, 17, 49);
}
.Title {
    font-weight: 700;
    color: rgb(78, 59, 77);
    margin-right: .5rem;
}
.Email, .Phone {
    margin-top: .3rem;
    margin-right: .5rem;
}
.Buttons {
    float: right;
    
}
.EditBtns {
    display: inline-block;
}
.EditBtns button {
    margin-left: .5rem;
}

.PriorityShiftButtons {
    display: inline-block;
    margin-right: .5rem;
}
