
@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _toolbar_height, _mobile_header_height_with_padding, _safe-area-modifier from variables;

.ContentWrapper {
    --padding-cap: 1rem;
    --padding-side: 1rem;

    padding: var(--padding-cap) var(--padding-side);
    display: flex;
    padding-top: calc(env(safe-area-inset-top) + _mobile_header_height_with_padding + var(--padding-cap));
    padding-bottom: calc(env(safe-area-inset-bottom) + _toolbar_height + var(--padding-cap));
    padding-left: calc(env(safe-area-inset-left) + var(--padding-side));
    padding-right: calc(env(safe-area-inset-right) + var(--padding-side));
}

@media only screen and (min-width: _menuwidth) {
    .ContentWrapper {
        padding-top: calc(_mobile_header_height_with_padding + var(--padding-cap) );
    }
}

@media only screen and (min-width: _menuwidth) and (orientation: portrait) {
    .ContentWrapper {
        padding-top: calc(env(safe-area-inset-top) + _mobile_header_height_with_padding + var(--padding-cap) );
    }
}