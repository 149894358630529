@value variables: '../../styles/variables.css';
@value _menuwidth, _widescreen, _burgundy_dark, _sidebar_width from variables;

.Readingpane {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

@media only screen and (min-width: _menuwidth) {
    .Readingpane {
       margin-right: calc(_sidebar_width - env(safe-area-inset-right));
       margin-left: 0;
    }
}

.ReadingpaneTitle {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #999;
}
.NavList {
    margin: 0 0 1rem;
    padding: 0;
    list-style: none;
}
.ListItem {
    display: block;
}
.NavButton {
    border: 1px solid #ddd;
    padding: .2rem .5rem .3rem;
    background: #fff;
    cursor: pointer;
    text-decoration: none;
    color: #333;
    display: block;
}
.NavButton:focus {
    outline: 0
}
.Active {
    background: rgb(243, 157, 229);
}
@media (hover: hover) { 
    .NavButton:hover {
        background: rgb(245, 209, 239)
    }
    .Active:hover {
        background: rgb(243, 157, 229);
    }
}
.LogoWrapper {
    max-width: 400px;
    width: 70%;
    margin: 3rem auto;
}
.LogoSVG path {
    fill: var(--burgundy-dark)
}